import React, { useState } from "react";
import * as crypto from "crypto-js";

import * as S from "./styles";
import Modal from "../../components/v2/Modal";
import Flex from "../../components/v2/common/Flex";
import { Button, TextButton } from "../../components/v2/common/Button";
import { BE_API_BASE_URL, SEED } from "../../constants/env";
import useCallAxios from "../../hooks/useAxiosCall";
import { toast } from "react-toastify";
import { ErrorCross, Tick, WarningIcon } from "../../assets/svg";
import { TOAST_OPTIONS } from "../../constants";
import { useGetSecHeaders } from "../../hooks";
import { SpinnerLoader } from "../../components/v2/common/SpinningLoader";
import { useRecoilState } from "recoil";
import { sessionState } from "../../state/globalState";
import { setUserInLocalStorage } from "../../helpers/localStorage";
import { useAppState } from "../../providers/AppStateProvider";

const DisconnectWalletModal = ({ showModal, setShowModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useRecoilState(sessionState);

  const { setCloseConnectWalletModal } = useAppState();

  function toggleModal() {
    setShowModal(prev => !prev);
  }

  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  async function confirmDisconnect() {
    const url = `${BE_API_BASE_URL}/user/wallet/disconnect`;
    try {
      setIsLoading(true);
      const resp = await callAxiosFunc({
        method: "PUT",
        url,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });

      if (resp?.err) {
        const errMessage = resp?.err?.response?.data?.message;
        throw new Error(errMessage);
      } else {
        const updatedSession = {
          ...session,
          user: {
            ...session.user,
          },
        };
        if (updatedSession?.user?.address) {
          delete updatedSession.user.address;
        }
        setSession(updatedSession);
        const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
        setUserInLocalStorage(encryptedState);
        setCloseConnectWalletModal(false); // This variable is used to control the connectWalletModal. The variable causes bugs when we manually disconnect the wallet, so need to set it as false

        toast(
          <Flex alignItems="center">
            <Tick style={{ height: "15px", width: "15px", marginRight: "14px" }} />
            Wallet disconnected.
          </Flex>,
          TOAST_OPTIONS,
        );
      }
    } catch (err) {
      toast.error(
        <Flex alignItems="center">
          <ErrorCross style={{ height: "40px", width: "40px" }} />
          {typeof err === "string" ? err : "Something went wrong. Please try again!"}
        </Flex>,
        TOAST_OPTIONS,
      );
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  }
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      toggleModal={toggleModal}
      customStyles={{ maxWidth: "501px" }}
    >
      <h1 style={{ color: "#fff", fontSize: "32px" }}>Disconnect Wallet</h1>
      <p style={{ color: "#A498ED" }}>
        You are about to disconnect your wallet. You can always reconnect later if needed.
      </p>
      <Flex columnGap="32" justifyContent="flex-end">
        <TextButton disabled={isLoading} onClick={() => setShowModal(false)}>
          Cancel
        </TextButton>
        <S.ConfirmDisconnectButton disabled={isLoading} isLoading={false} onClick={confirmDisconnect}>
          {isLoading ? <SpinnerLoader /> : "Yes, disconnect"}
        </S.ConfirmDisconnectButton>
      </Flex>
    </Modal>
  );
};

export default DisconnectWalletModal;
