import React, { useContext, useEffect, useRef, useState } from "react";
import * as crypto from "crypto-js";

import * as S from "./styles";
import Chatbox from "./Chatbox";
import { MessagesIcon } from "../../../assets/svg";
import { SocketContext } from "../../../providers/SocketProvider";
import { useRecoilState } from "recoil";
import { sessionState } from "../../../state/globalState";
import { WSS_CRYPTO_SEED } from "../../../constants/env";
import { socketAuthState } from "../../../recoil/socketAuthState";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const Chat = ({ poolData, matchId, chatRoomId }) => {
  const [showChatbox, setShowChatbox] = useState(false);
  const [roomChats, setRoomChats] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isChatPaused, setIsChatPaused] = useState(false);

  const [isSocketAuthenticated, setIsSocketAuthenticated] = useRecoilState(socketAuthState);

  const [didFailAuth, setDidFailAuth] = useState(false); // This bool will be used to re-emit the joinRoom event IN CASE the event failed once (which happens if the user tries to join a room while being unauthenticated in sockets). Using ref as state was not updating in time

  const [session] = useRecoilState(sessionState);

  const { socket } = useContext(SocketContext);

  function toggleChatbox() {
    setShowChatbox(prev => !prev);
  }
  function closeChatbox() {
    setShowChatbox(false);
  }


  function handleMessageReceived(data) {
    if (data?.message?.type === "action") {
      if (data?.message?.value === "room_paused") {
        setIsChatPaused(true);
      } else if (data?.message?.value === "room_resumed") {
        setIsChatPaused(false);
      }
      return;
    }
    setRoomChats(prev => [...prev, data]);
    if (!showChatboxRef.current) {
      setUnreadMessages(prev => prev + 1);
    }
  }

  function handleJoinRoom(event) {
    if (event.error) {
      if (event?.data?.joined === false && event.data?.msg === "Trying to join a room but user is not authenticated.") {
        // If a user directly visits a pool page in the browser, it is possible that they are not authenticated in the websockets when they try to join a room
        // If that happens, the authenticateuser event should be emitted.
        // The useEffect will handle the functionality to reemit the join room event once the user has been successully authenticated (in sockets)
        const encryptedUserId = crypto.AES.encrypt(session?.user?.id, WSS_CRYPTO_SEED).toString();

        const encryptedSecToken = crypto.AES.encrypt(session?.access_token, WSS_CRYPTO_SEED).toString();

        const encryptedIp = crypto.AES.encrypt(session?.ip, WSS_CRYPTO_SEED).toString();

        const obj = { userId: encryptedUserId, secToken: encryptedSecToken, ipAddress: encryptedIp };
        socket.emit("authenticateUser", obj);

        setDidFailAuth(true);
      }
    }
    if (!event.error) {
      setRoomChats(event.data?.roomHistory);
      setIsChatPaused(event.data?.isPaused);
      if (event.data?.roomHistory?.length > 1) setShowChatbox(true); // Open chatbox if there are previous chats
    }
  }

  const showChatboxRef = useRef(showChatbox);

  // Keep the ref updated with the latest value of showChatbox. Need this as handleMessageReceived is not capturing the latest value of the showChatbox state
  useEffect(() => {
    showChatboxRef.current = showChatbox;

    if (showChatbox) setUnreadMessages(0);
  }, [showChatbox]);

  useEffect(() => {
    let joinRoomRequestObj = {
      roomId: chatRoomId,
      extProps: {
        poolId: Number(poolData?.id),
      },
    };
    if (poolData.poolType === "BRACKETS") {
      joinRoomRequestObj = {
        roomId: chatRoomId,
        cmdRoomId: poolData?.id?.toString(),
        extProps: {
          matchId: matchId,
          poolId: Number(poolData?.id),
        },
      };
    }
    if (poolData.poolType === "MULTI_STAGE") {
      let tempArr = chatRoomId.split("_");

      let stageId = tempArr[1]; // Match Id will be in the format : `{poolId}_{stageId}_{matchId}` for multistage pools (matchId only for bracket pools). We will make use of this format to get the stageId.

      let stageMatchId = tempArr[2]; // If pool is NOT bracket, tempArr[2] will result in undefined. We check for this in the code below
      joinRoomRequestObj = {
        roomId: chatRoomId,
        cmdRoomId: poolData?.id?.toString(),
        extProps: {
          ...(stageMatchId ? { matchId: stageMatchId } : {}),
          poolId: Number(poolData?.id),
          stageId: Number(stageId),
        },
      };
    }
    socket.emit("joinRoom", joinRoomRequestObj);
    socket.on("roomMessage", handleMessageReceived);
    socket.on("joinRoomResponse", handleJoinRoom);

    // The following will run when a user is authenticated.
    socket.on("authenticateUserResponse", result => {
      if (!didFailAuth) return;

      if (result?.data?.authenticated) {
        socket.emit("joinRoom", joinRoomRequestObj);
        setIsSocketAuthenticated(true);
      }
    });

    return () => {
      socket.off("roomMessage", handleMessageReceived);
      socket.emit("leaveRoom", {
        roomId: chatRoomId,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
  }, [chatRoomId]);

  const chatRef = useRef(null);
  useOnClickOutside(chatRef, event => {
    if (closeChatbox) {
      closeChatbox();
    }
  });

  return (
    <S.ChatContainer ref={chatRef}>
      {showChatbox && (
        <Chatbox
          isChatPaused={isChatPaused}
          roomChats={roomChats}
          chatRoomId={chatRoomId}
          poolData={poolData}
          closeChatbox={closeChatbox}
        />
      )}
      <S.ChatButton onClick={toggleChatbox}>
        <MessagesIcon />
        {unreadMessages > 0 && <S.Badge>{unreadMessages}</S.Badge>}
      </S.ChatButton>
    </S.ChatContainer>
  );
};

export default Chat;
