import React, { useContext, useEffect, useRef, useState } from "react";
import * as S from "./styles";
import ChatRow from "./ChatRow";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { CallIcon, CloseIcon, SendIcon } from "../../../assets/svg";
import { SocketContext } from "../../../providers/SocketProvider";
import { Tooltip } from "antd";

const Chatbox = ({ closeChatbox, poolData = {}, chatRoomId, roomChats = [], isChatPaused = false }) => {
  const [userInput, setUserInput] = useState("");
  const { socket } = useContext(SocketContext);

  const nickColors = [
    "#68D773",
    "#D15D52",
    "#FFDE69",
    "#71CCFF",
    "#CD42FB",
    "#E6870A",
    "#C1D300",
    "#7B6FFF",
    "#C1BCBC",
    "#0C9117",
    "#45FCF9",
    "#75AA41",
    "#75AA41",
    // "#FFFFFF",
  ];

  const colorMap = {};

  function uuidToIndex(uuid = "", range = 13) {
    // Create a simple hash from the UUID
    if (!uuid) return 0; // If no ID for any reason, show the first color
    if (colorMap[uuid]) return colorMap[uuid];
    let hash = 0;
    for (let i = 0; i < uuid.length; i++) {
      const char = uuid.charCodeAt(i);
      hash = (hash * 31 + char) % 0xffffffff; // Keep hash manageable
    }

    // Map the hash to an index within the range
    const resultColor = hash % range;
    colorMap[uuid] = resultColor;
    return resultColor;
  }

  function getUserColor(chat) {
    if (chat?.message?.type === "system") return "#FFDE69";
    return nickColors[uuidToIndex(chat.userId, nickColors.length)];
  }

  const inputRef = useRef();
  const scrollToRef = useRef();

  useEffect(() => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [roomChats]);

  // Scrolling to last message when first visiting the section
  useEffect(() => {
    if (scrollToRef && scrollToRef?.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (inputRef && inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  function sendMessage() {
    if (!userInput) return;
    socket.emit("sendRoomMessage", {
      type: "text",
      value: userInput,
      roomId: chatRoomId,
    });
    setUserInput("");
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  function sendRequestAdminEvent() {
    if (isChatPaused) return;
    socket.emit("sendRoomMessage", {
      type: "text",
      value: "/admin",
      roomId: chatRoomId,
    });
  }

  function handleUserInput(e) {
    setUserInput(e.target?.value);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      sendMessage();
    }
  }

  return (
    <S.ChatBox>
      <S.ChatBoxHeader>
        <S.FlexRow>
          {/* TODO: Show game image below */}
          {/* <MessagesSquareIcon />  */}
          <S.ChatHeaderImg src={poolData?.assets?.gameThumbnail} />
          <S.HeaderTextContainer>
            <div>{poolData?.name}</div>
            <small>ID: {poolData?.id}</small>
          </S.HeaderTextContainer>
        </S.FlexRow>

        <S.CloseChatButton onClick={closeChatbox}>
          <CloseIcon />
        </S.CloseChatButton>
      </S.ChatBoxHeader>
      <S.ChatMessagesContainer>
        {roomChats.map((chat, i) => (
          <ChatRow
            chat={chat}
            color={getUserColor(chat)}
            prevMsgTimestamp={i === 0 ? null : roomChats[i - 1]?.timestamp}
          />
        ))}
        <div style={{ visibility: "hidden" }} ref={scrollToRef} />
      </S.ChatMessagesContainer>
      <S.InputContainer>
        <S.ChatInput
          ref={inputRef}
          value={userInput}
          onKeyDown={handleKeyDown}
          onChange={handleUserInput}
          disabled={isChatPaused}
        />
        <Tooltip
          color="#7265C8"
          placement="top"
          title={isChatPaused ? "Chat has been paused by admin" : "Send"}
          overlayInnerStyle={{ borderRadius: "4px", maxWidth: "200px" }}
        >
          {/* using shouldDisable because if we disable the button, the tooltip does not function */}
          <S.SendButton onClick={sendMessage} shouldDisable={!userInput || isChatPaused}>
            <SendIcon />
          </S.SendButton>
        </Tooltip>
        <Tooltip
          color="#7265C8"
          placement="top"
          title={isChatPaused ? "Chat has been paused by admin" : "Request Admin"}
          overlayInnerStyle={{ borderRadius: "4px", maxWidth: "200px" }}
        >
          <S.RequestAdminButton onClick={sendRequestAdminEvent} shouldDisable={isChatPaused}>
            <CallIcon />
          </S.RequestAdminButton>
        </Tooltip>
      </S.InputContainer>
    </S.ChatBox>
  );
};

export default Chatbox;
