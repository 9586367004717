import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Select } from "antd";
import Modal from "../Modal";
import { TickSuccessNewGold, DropdownIcon } from "../../../assets/svg";
import Confetti from "../../../assets/confetti.png";
import AddToCalendarIcon from "../../../assets/svg/add-to-calendar-icon.svg";
import { GOOGLE_CALENDAR_URL, OUTLOOK_CALENDAR_URL } from "../../../constants/env";
import { Text } from "../common/Text";
import Flex from "../common/Flex";
import Avatar from "../common/Avatar";
import { truncateWithDots } from "../../../helpers/utils";

const MainContainer = styled.div`
  width: 540px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 50px; */
  padding: 50px 50px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: url(${Confetti});
  height: 100%;
  top: 0;
  /* padding-top: 50px; */
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 325;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
`;

const Subtitle = styled.p`
  margin-top: 20px;
  height: 51px;
  width: 315px;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #a498ed;
`;

const CalendarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const CalendarContainer = styled(Select)`
  height: 37px;
  width: 217px;
  border-radius: 6px;
  color: #fff;
  background: #3f2faa url(${AddToCalendarIcon}) no-repeat;
  background-position: left 5% top 50%;
  text-align: center;
  display: flex;
  align-items: center;
`;

const CalendarOption = styled(Select.Option)``;

const WhatsappGroupsContainer = styled.div`
  width: 329px;
  max-height: 130px;
  background-color: #3f2faa;
  border-radius: 4px;
  overflow-y: auto;
  padding: 6px;
  display: flex;
  row-gap: 6px;
  flex-direction: column;
  justify-content: space-between;
`;

const WhatsappGroupRow = styled.div`
  height: 50px;
  border-radius: 4px;
  padding: 7px 9px;
  background-color: #28292a;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WhatsappJoinBtn = styled.a`
  all: unset;
  padding: 6px 14px;
  background-color: #26d367;
  color: #28292a;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 900;

  &:hover {
    color: #28292a;
  }
`;

const AddToCalendarModal = ({
  showModal,
  toggleModal,
  url,
  eventName,
  desc,
  poolId,
  gameId,
  startDate,
  endDate,
  organizerInfo = [],
}) => {
  const icsEvent = {
    title: eventName,
    description: desc,
    location: `${url}%23/pool/${poolId}?g=${gameId}`,
    startTime: moment(startDate).format("YYYYMMDDTHHmmss"),
    endTime: moment(endDate).format("YYYYMMDDTHHmmss"),
  };

  const googleCalendarUrl = `${GOOGLE_CALENDAR_URL}&text=${icsEvent?.title}&details=${icsEvent?.description}&location=${icsEvent?.location}&dates=${icsEvent?.startTime}/${icsEvent?.endTime}`;
  const outlookCalendarUrl = `${OUTLOOK_CALENDAR_URL}&subject=${icsEvent?.title}&body=${icsEvent?.description}&startdt=${icsEvent?.startTime}&enddt=${icsEvent?.endTime}&location=${icsEvent?.location}`;

  // function that generates an ics file so can be imported in Apple Calendar or other calendars
  const generateAppleCalendarEvent = () => {
    const formatCalendar = () => {
      // set filename
      let ical = "BEGIN:VCALENDAR\n";
      ical += "VERSION:2.0\n";
      ical += "PRODID:-//My Company//My Product//EN\n";
      ical += "BEGIN:VEVENT\n";
      ical += "UID:" + icsEvent.id + "\n";
      ical += "DTSTAMP:" + `${icsEvent.startTime}` + "\n";
      ical += "DTSTART:" + `${icsEvent.startTime}` + "\n";
      ical += "DTEND:" + `${icsEvent.endTime}` + "\n";
      ical += "SUMMARY:" + icsEvent.title + "\n";
      ical += "LOCATION:" + icsEvent.location + "\n";
      ical += "END:VEVENT\n";
      ical += "END:VCALENDAR";

      return ical;
    };
    // changes the name of the file before downloading the file
    const calendar = formatCalendar();
    const filename = `${icsEvent.title}.ics`;
    const a = document.createElement("a");
    const file = new Blob([calendar], { type: "text/calendar;charset=utf-8" });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  };

  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <MainContainer>
        <TextContainer>
          <TickSuccessNewGold />
          <Title>You joined successfully</Title>
          <Subtitle>We&apos;re processing your request. It will take 5 minutes to be available.</Subtitle>
        </TextContainer>
        <CalendarWrapper>
          <CalendarContainer
            bordered={false}
            dropdownStyle={{ zIndex: 9999, backgroundColor: "#3f2faa", color: "#fff" }}
            suffixIcon={<DropdownIcon />}
            defaultValue="Add to calendar"
            onChange={value => {
              switch (value) {
                case "apple":
                  generateAppleCalendarEvent();
                  break;
                case "google":
                  window.open(googleCalendarUrl, "_blank");
                  break;
                case "outlook":
                  window.open(outlookCalendarUrl, "_blank");
                  break;
                default:
                  break;
              }
            }}
          >
            <CalendarOption style={{ color: "#fff", backgroundColor: "#3f2faa" }} value="apple">
              Apple Calendar
            </CalendarOption>
            <CalendarOption style={{ color: "#fff", backgroundColor: "#3f2faa" }} value="google">
              Google Calendar
            </CalendarOption>
            <CalendarOption style={{ color: "#fff", backgroundColor: "#3f2faa" }} value="outlook">
              Outlook Calendar
            </CalendarOption>
          </CalendarContainer>
        </CalendarWrapper>

        {organizerInfo.some(info => info.whatsapp) ? (
          <>
            <Text color="#877ADD" fontSize="16px">
              We found these whatsapp groups that might be useful
            </Text>

            <WhatsappGroupsContainer className="custom-scroll">
              {organizerInfo
                ?.filter(info => !!info?.whatsapp)
                ?.map(info => (
                  <WhatsappGroupRow>
                    <Flex columnGap="8" alignItems="center">
                      <Avatar src={info.whatsappGroupProfPic} />
                      <span>{truncateWithDots(info.whatsappGroupName, 20)}</span>
                    </Flex>
                    <div>
                      <WhatsappJoinBtn href={info.whatsapp} target="_blank" rel="noreferrer noopener">
                        Join
                      </WhatsappJoinBtn>
                    </div>
                  </WhatsappGroupRow>
                ))}
            </WhatsappGroupsContainer>
          </>
        ) : null}
      </MainContainer>
    </Modal>
  );
};

export default AddToCalendarModal;
