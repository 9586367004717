import React from "react";
import moment from "moment";

import Flex from "../common/Flex";
import * as S from "./styles";

const DateTag = ({ prevMsgDate, currMsgDate }) => {
  if (!prevMsgDate) return null;
  // Explanation. The DateTag takes the current message's timestamp and the previous message's timestamp. If the two messages were sent on different dates, the date tag UI is displayed.
  // Another thing that is checked is that if the current message timestamp is of today's date (i.e. the message was sent today), then we show "Today" text instead of the date. Similar case for 'Yesterday'
  if (prevMsgDate && !prevMsgDate.isSame(currMsgDate, "day")) {
    const today = moment().isSame(currMsgDate, "day");
    const yesterday = moment().subtract(1, "day").isSame(currMsgDate, "day");

    return (
      <Flex justifyContent="center">
        <S.DateTag>{today ? "Today" : yesterday ? "Yesterday" : moment(currMsgDate).format("DD MMMM YYYY")}</S.DateTag>
      </Flex>
    );
  }
  return null;
};

const ChatRow = ({ chat, color = "#7B6FFF", prevMsgTimestamp }) => {
  const { message = {}, userNick, timestamp = "", isAdmin = false } = chat;

  const currMsgDate = moment(timestamp);
  const prevMsgDate = prevMsgTimestamp ? moment(prevMsgTimestamp) : null;

  if (message.type === "text")
    return (
      <>
        <DateTag prevMsgDate={prevMsgDate} currMsgDate={currMsgDate} />
        <S.MessageRow color={isAdmin ? "yellow" : color}>
          <span>{userNick} {isAdmin ? "[Mod]" : null}:</span>
          <span style={{color: isAdmin? "yellow" : "#fff"}}>{message.value}</span>
          <small>{moment(timestamp).format("hh:mm A")}</small>
        </S.MessageRow>
      </>
    );

  if (message.type === `system`)
    return (
      <S.NotifRow>
        {/* {chatStatus === `STARTED`
          ? `You're now chatting with ${participant}`
          : `Your chat with ${participant} has ended`} */}
        {/* <span>{userNick}:</span> */}
        {message.value}
      </S.NotifRow>
    );

  return <></>;
};

export default ChatRow;
